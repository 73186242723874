import React from 'react';
import up from '../../assets/up.png';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
    wrapper: {
        marginBottom: "10px",
        marginTop: "10px"
    },
    text: {
        marginLeft: "8px"
    },
    image: {
        verticalAlign: "bottom"
    }
})

export default function BackToTop()
{
    const classes = styles();
    return (
        <div className={classes.wrapper}>
            <a href="#top">
                <img src={up} className={classes.image} alt="Go to top" height="20px" width="20px" />
                <span className={classes.text}>Back to the Top</span>
            </a>
        </div>
    )
}
