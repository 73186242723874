import { createUseStyles } from 'react-jss';
import w80 from '../../assets/w80-192x192.png';
import ProductSelectorItem from './ProductSelectorItem';

const styles = createUseStyles({
    wrapper: {
        margin: "auto",
        padding: "10px",
        textAlign: "center",
        borderRadius: "10px",
        borderStyle: "solid",
        borderColor: "var(--main-border-color)",
        borderWidth: "2px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center"
    }
})

export default function ProductSelector({onSelect})
{
    const classes = styles();
    return(
        <div className={classes.wrapper}>
            <ProductSelectorItem
                code="W80"
                icon={w80}
                description="W80 is a tool to help organize job searching data, keep up with employment appointments and support the candidates in their persuit of employment opportunities."
                onSelect={() => onSelect('W80')} />
        </div>
    )
}