import React from 'react';
import { createUseStyles } from 'react-jss';
import BackToTop from '../Common/BackToTop';
import { ProductComponentMap } from './ProductCatalog';

const styles = createUseStyles({
    wrapper: {
        padding: "10px",
        marginTop: "20px",
        width: "80vw",
        textAlign: "center",
        borderRadius: "10px",
        borderStyle: "solid",
        borderColor: "var(--main-border-color)",
        borderWidth: "2px",
    }
})

export default function ProductDetails({code})
{
    const classes = styles();
    if (!code || !ProductComponentMap[code])
        return <React.Fragment />;
    else
    {
        const ProductComponent = ProductComponentMap[code];
        return (
            <React.Fragment>
                <div className={classes.wrapper}>
                    <ProductComponent />
                </div>
                <BackToTop />
            </React.Fragment>
        )
    }
}
