import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
    wrapper: {
        display: "flex",
        margin: "10px",
        padding: "10px",
        whiteSpace: "wrap",
        color: "var(--secondary-color)",
        background: "var(--secondary-background)",
        border: "1px solid var(--secondary-color)",
        borderRadius: "4px",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#e2e2e2"
        },
        "& img": {
            border: "1px solid transparent",
        }
    },
    description: {
        marginLeft: "10px",
        marginRight: "10px",
        fontSize: "1.0em",
        alignSelf: "flex-start"
    }
})

export default function ProductSelectorItem({description, icon, onSelect})
{
    const classes = styles();
    return(
        <div className={classes.wrapper} onClick={onSelect}>
            <div>
                <img src={icon} height="64" width="64" alt={description} />
            </div>
            <div className={classes.description}>
                {description}
            </div>
        </div>
    )
}