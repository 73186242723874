import React from 'react';
import docker from '../../../assets/docker.png';
import dotnet from '../../../assets/dotnet.png';
import mongodb from '../../../assets/leaf.svg';
import reactjs from '../../../assets/reactjs.svg';
import redux from '../../../assets/redux.png';
import Zoom from 'react-medium-image-zoom'
import { Tooltip } from 'react-tooltip';
import 'react-medium-image-zoom/dist/styles.css'
import w80Schedule from '../../../assets/w80/w80-schedule.png';
import w80login from '../../../assets/w80/w80-login-screen.png'
import w80Statistics from '../../../assets/w80/w80-statistics.png';
import w80OpenApplication from '../../../assets/w80/w80-open-applications.png';
import w80RejectedApplication from '../../../assets/w80/w80-rejected-application.png';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
    header: {
        fontSize: "1.5em",
        textAlign: "center",
        marginTop: "40px",
        margin: "auto",
        paddingBottom: "20px"
    },
    headerIcon: {
        border: "1px solid white",
        margin: "20px"
    },
    section: {
        marginTop: "40px",
        marginBottom: "40px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "flex-start",
        "& div:first-child": {
            flex: "2"
        },
        "& div:nth-child(2)": {
            flex: "1"
        },
    },
    sectionImage: {
        width: "50vw",
        margin: "20px"
    },
    sectionText: {
        fontSize: "1.3rem",
        margin: "20px"
    },
    technologies: {
        fontSize: "1.3rem",
        margin: "20px"
    },
    technologyIcons: {
        marginTop: "40px",
        marginBottom: "40px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    logo: {
        fontSize: "1.0rem",
        margin: "auto",
        "@media (max-width: 400px)": {
            fontSize: ".5rem"
        },
        "@media (max-width: 800px) and (min-width: 400px)": {
            fontSize: ".75rem"
        }
    }
})

export default function W80()
{
    const classes = styles();
    return(
        <React.Fragment>
            <div className={classes.header}>
                <div className={classes.logo}>
<pre>
██╗    ██╗ █████╗  ██████╗<br/>
██║    ██║██╔══██╗██╔═████╗<br/>
██║ █╗ ██║╚█████╔╝██║██╔██║<br/>
██║███╗██║██╔══██╗████╔╝██║<br/>
╚███╔███╔╝╚█████╔╝╚██████╔╝<br/>
 ╚══╝╚══╝  ╚════╝  ╚═════╝<br/>
 </pre>
                </div>
                <div>
                    W80 is a tool for job seekers to organize their job hunting data, stay on top of all the job related appointments
                    and compare previous and current employment opportunities to understand areas which can be improved.
                    <br />
                    W80 is open source and under a BSD style license.
                </div>
            </div>
            <hr/>
            <div className={classes.section}>
                <div>
                    <div className={classes.sectionImage}>
                        <Zoom>
                            <img src={w80OpenApplication} width="100%" height="100%" alt="W80 open applications section" />
                        </Zoom>
                    </div>
                </div>
                <div className={classes.sectionText}>
                    W80 allows you to gather all of your job searching data as applications. As progress of the employment
                    opportunity moves forward, the application also move forward through a predefined set of states. The
                    states correspond to various stages of a job application, such as screening, assessment, decision, etc.
                    Applications can be organized based on state, coming up appointments or compensation.
                </div>
            </div>
            <hr/>
            <div className={classes.section}>
                <div className={classes.sectionText}>
                    Applications can be archived for future use, rejected if the opportunity falls through or marked as
                    accepted if the candidate is hired. In the future, if needed, archived applications can be un-archived and put
                    into an open state.
                </div>
                <div>
                    <div className={classes.sectionImage}>
                        <Zoom>
                            <img src={w80RejectedApplication} width="100%" height="100%" alt="W80 rejected applications section" />
                        </Zoom>
                    </div>
                </div>
            </div>
            <hr/>
            <div className={classes.section}>
                <div className={classes.sectionText}>
                    A multi view calendar contained in W80 provides a global view of all appointments for each of the open
                    applications. It includes a monthly, weekly and daily view plus an agenda summary view. A user has
                    the ability to enable email and/or browser alerts for all of their calendar appointments.
                </div>
                <div>
                    <div className={classes.sectionImage}>
                        <Zoom>
                            <img src={w80Schedule} width="100%" height="100%" alt="W80 schedule section" />
                        </Zoom>
                    </div>
                </div>
            </div>
            <hr/>
            <div className={classes.section}>
                <div>
                    <div className={classes.sectionImage}>
                        <Zoom>
                            <img src={w80Statistics} width="50%" height="50%" alt="W80 statistics section" />
                        </Zoom>
                    </div>
                </div>
                <div className={classes.sectionText}>
                    As applications are moved through different states and are rejected or accepted, the system collects
                    various data points about them. The statistics view in the system shows the results of the calculations.
                </div>
            </div>
            <hr/>
            <div className={classes.section}>
                <div className={classes.sectionText}>
                    W80 is fully localized and currently supports English and German. Support for additional languages is in the pipeline.
                    <br />
                    A preview version of W80 is deployed at <a href="https://w80.silverkinetics.dev">https://w80.silverkinetics.dev</a>.
                    Open registration is closed at this point, so you will need to request an invitation code to be able to
                    create an account. All that is required is a valid email address.
                </div>
                <div>
                    <div className={classes.sectionImage}>
                        <Zoom>
                            <img src={w80login} width="100%" height="100%" alt="W80 login page" />
                        </Zoom>
                    </div>
                </div>
            </div>
            <hr/>
            <div className={classes.technologies}>
                W80 is open source and built from the ground up using modern technologies and implementing all the industry standards and best practices.
                <div className={classes.technologyIcons}>
                    <div>
                        <img data-tooltip-id="technology-docker" src={docker} height="100px" alt="Docker" />
                        <Tooltip id="technology-docker" delayShow="100" place="bottom">Docker</Tooltip>
                    </div>
                    <div>
                        <img data-tooltip-id="technology-dotnet8" src={dotnet} height="100px" alt=".NET8" />
                        <Tooltip id="technology-dotnet8" delayShow="100" place="bottom">.NET8</Tooltip>
                    </div>
                    <div>
                        <img data-tooltip-id="technology-mongodb" src={mongodb} height="100px" alt="MongoDB" />
                        <Tooltip id="technology-mongodb" delayShow="100" place="bottom">MongoDB</Tooltip>
                    </div>
                    <div>
                        <img data-tooltip-id="technology-reactjs" src={reactjs} height="100px" alt="ReactJS" />
                        <Tooltip id="technology-reactjs" delayShow="100" place="bottom">ReactJS</Tooltip>
                    </div>
                    <div>
                        <img data-tooltip-id="technology-redux" src={redux} height="100px" alt="Redux" />
                        <Tooltip id="technology-redux" delayShow="100" place="bottom">Redux</Tooltip>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
