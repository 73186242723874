import Header from "./components/Header/Header";
import ProductCatalog from "./components/Products/ProductCatalog";
function App() {
  return (
    <div className="App">
      <Header />
      <ProductCatalog />
    </div>
  );
}

export default App;
