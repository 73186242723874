import { createUseStyles } from 'react-jss';
import { Tooltip } from 'react-tooltip';
import github from '../../assets/github.png';
import logoBackground from '../../assets/logo-background.png';

const styles = createUseStyles({
    wrapper: {
        top: "6px",
        padding: "6px",
        position: "sticky",
        zIndex: 1000,
        margin: "6px",
        borderRadius: "16px",
        backgroundImage: `linear-gradient(rgba(73, 73, 73, 0.9), rgba(28, 28, 28, 0.9)), url(${logoBackground})`,
        display: "flex",
        justifyContent: "space-between"
    },
    main: {
        marginLeft: "10px",
        fontSize: "2.5em",
        textAlign: "center",
        display: "inline-block"
    },
    subscript: {
        marginLeft: "20px",
        display: "inline-block",
        fontSize: "18px"
    },
    github: {
        marginTop: "10px",
        marginRight: "10px",
        display: "inline-block",
        fontSize: "18px"
    }
})

export default function Header()
{
    const classes = styles();
    return(
        <div className={classes.wrapper}>
            <div className={classes.main}>
                Şǐlvёr Ӄǐnёtǐcs
                <div className={classes.subscript}>
                    Software Engineering Industries
                </div>
            </div>
            <div className={classes.github}>
                <a href="https://github.com/silverkineticsindustries/">
                    <img data-tooltip-id="visit-github" src={github} height="32px" width="32px" alt="Visit GitHub" />
                    <Tooltip id="visit-github" delayShow="100" place="left">Visit Silver Kinetics Industries on GitHub</Tooltip>
                </a>
            </div>
        </div>
    )
}