import { useState } from 'react';
import W80 from './Items/W80';
import { createUseStyles } from 'react-jss';
import ProductSelector from './ProductSelector';
import ProductDetails from './ProductDetails';

const styles = createUseStyles({
    wrapper: {
        margin: "auto",
        padding: "10px",
        width: "80vw",
        textAlign: "center",
        borderRadius: "10px"
    },
    catalogHeader: {
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "1.75em",
        textAlign: "center",
        textTransform: "capitalize"
    }
})

export const ProductComponentMap = {
    "W80": () => <W80 />
}

export default function ProductCatalog()
{
    const classes = styles();
    const [selectedProduct, setSelectedProduct] = useState();

    const onProductSelection = (code) => {
        setSelectedProduct(code);
    }

    return(
        <div id="top" className={classes.wrapper}>
            <div>
                <div className={classes.catalogHeader}>Product Catalog</div>
                <div>
                    <ProductSelector onSelect={onProductSelection} />
                </div>
            </div>
            <div>
                <ProductDetails code={selectedProduct} />
            </div>
        </div>
    )
}